import { BeachAccessOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Divider,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import {
  VacationsAccrualPeriodEntry,
  VacationsScheduleEntry,
} from '@octopus/api';

import { requestVacationObject } from '../utils/types';

export default function ReviewAndSubmitStep({
  accrualPeriod,
  requestVacationMSV,
  request,
  setRequest,
  setIsLoading,
  submitVacationsScheduler,
}: {
  accrualPeriod: VacationsAccrualPeriodEntry & { nextSequence: number };
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  submitVacationsScheduler: (
    request: requestVacationObject,
  ) => Promise<VacationsScheduleEntry>;
}) {
  const theme = useTheme();
  return (
    <Box
      test-id={'step-box'}
      sx={{
        px: 2,
        pt: 8,
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'stretch',
        bgcolor: theme.palette.background.secondary,
        gap: 4,
        height: '100%',
      }}
    >
      <Box>
        <Box>
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={550}
            word-wrap={'break-word'}
          >
            {`Revise as informações abaixo antes de continuar.
              Após solicitar, seu pedido passará pela etapa de 
              aprovações e você será notificado após esta etapa.`}
          </Typography>
        </Box>
        {renderCard(accrualPeriod, request, theme)}
      </Box>
      <Box sx={{ flexGrow: 1, alignContent: 'flex-end' }}>
        <Button
          color={'primary'}
          onClick={() => {
            setIsLoading(true);
            submitVacationsScheduler(request)
              .then((entry: VacationsScheduleEntry) => {
                console.log(JSON.stringify(entry));
                requestVacationMSV.goForward();
                setIsLoading(false);
              })
              .catch((error) => {
                requestVacationMSV.goForward();
                setRequest({
                  ...request,
                  result: {
                    errorRuleCodes: Object.keys(error.stack.details),
                    ok: false,
                  },
                });
                setIsLoading(false);
              })
              .finally(() => setIsLoading(false));
          }}
          sx={{ width: '100%', py: 1, px: 2.5, height: '48px', gap: '8px' }}
        >
          Solicitar
        </Button>
      </Box>
    </Box>
  );
}

function renderCard(
  accrualPeriod: VacationsAccrualPeriodEntry,
  request: requestVacationObject,
  theme: Theme,
) {
  return requestIsValid(request) ? (
    <Card
      variant="elevation"
      sx={{
        border: '12px',
        mt: 2,
        padding: 2,
      }}
    >
      <Box
        component={BeachAccessOutlined}
        sx={{ height: '40px', width: 'auto', color: '#BABABF' }}
      />
      <Box sx={{ py: 1 }}>
        <Typography fontWeight={700} fontSize={'20px'} lineHeight={'28px'}>
          {request.duration} dias
        </Typography>
        <Typography
          fontWeight={500}
          fontSize={'16px'}
          variant={'h4'}
          lineHeight={'24px'}
        >
          {`De ${request.date.format('DD/MM/YYYY')} a ${request.date.add(request.duration - 1, 'day').format('DD/MM/YYYY')}`}
        </Typography>
      </Box>
      <Divider sx={{ color: theme.palette.strokes.light, py: 1 }} />
      {typeof request.sellVacation !== 'undefined' ? (
        <Box sx={{ pt: 2, pb: 1 }}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            fontSize={14}
            lineHeight={'20px'}
          >
            Deseja vender 10 dias das suas férias?
          </Typography>
          <Typography fontWeight={500} fontSize={16} lineHeight={'24px'}>
            {request.sellVacation ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ) : null}
      {typeof request.advanceThirteenth !== 'undefined' ? (
        <Box sx={{ pt: 2, pb: 1 }}>
          <Typography
            variant="subtitle1"
            fontWeight={500}
            fontSize={14}
            lineHeight={'20px'}
          >
            Deseja receber adiantamento de 50% do 13º?
          </Typography>
          <Typography fontWeight={500} fontSize={16} lineHeight={'24px'}>
            {request.advanceThirteenth ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ) : null}
      <Box sx={{ pt: 2, pb: 1 }}>
        <Typography
          variant="subtitle1"
          fontWeight={500}
          fontSize={14}
          lineHeight={'20px'}
        >
          Dias restantes disponíveis
        </Typography>
        <Typography fontWeight={500} fontSize={16} lineHeight={'24px'}>
          {accrualPeriod.daysAvailable -
            request.duration -
            (request.sellVacation ? 10 : 0)}
        </Typography>
      </Box>
    </Card>
  ) : null;
}
function requestIsValid(request: requestVacationObject) {
  return request.date && request.duration;
}
