import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { BeachAccessOutlined } from '@mui/icons-material';
import { Box, Container, Skeleton, Tab, Tabs, Typography } from '@mui/material';

import {
  SearchInput,
  VacationsAccrualPeriodEntry,
  VacationsScheduleSummary,
  fetchGetContractAccrualPeriods,
  fetchSearchAllScheduledVacations,
} from '@octopus/api';

import TabLabel from '../../../../modules/components/TabLabel';
import NextVacationsCardSaldo, {
  nextVacationsCardSaldoProps,
} from '../../../../modules/components/vacation-scheduler/nextVacationsCardSaldo';
import VacationsRequestedCard from '../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { DataFetching } from '../../../../modules/dataFetching';

import {
  VacationSchedulerTabs,
  vacationScheduleTabsConfig,
} from './utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

type PageRenderProps = {
  TitleIcon: React.ElementType;
  Title: React.ElementType;
};

function VacationSchedulerPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const { contractId } = useParams();
  const [tab, setTab] = useState<keyof typeof vacationScheduleTabsConfig>(
    VacationSchedulerTabs.next,
  );

  const switchTab = (tab: string) => {
    setTab(tab as keyof typeof vacationScheduleTabsConfig);
  };

  const pageRenderProps: PageRenderProps = {
    Title: () => <> Férias </>,
    TitleIcon: BeachAccessOutlined,
  };

  const accrualPeriodsQuery = useQuery({
    queryKey: [organizationId, contractId, tab, 'saldo'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => {
      return fetchGetContractAccrualPeriods({
        pathParams: {
          organizationId: organizationId ?? '',
          contractId: contractId ?? '',
        },
      });
    },
    enabled: !!organizationId,
  });

  const searchFilter: SearchInput = {
    filtering: {
      elements: {
        contractId: [contractId],
      },
    },
  };

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, tab, 'solicitadas'],
    refetchOnWindowFocus: false,
    refetchOnMount: false,

    queryFn: () => {
      return fetchSearchAllScheduledVacations({
        pathParams: {
          organizationId: organizationId,
        },
        body: searchFilter,
      });
    },
    enabled: !!organizationId,
  });

  return (
    <Box
      pt={9}
      height="100vh"
      sx={{
        backgroundColor: 'background.paper',
      }}
    >
      <Container maxWidth="lg">
        <Box
          data-testid={`payroll-type-header`}
          sx={{
            marginBottom: 5,
            display: 'flex',
            flexDirection: 'row',
            marginBotton: '100px',
          }}
        >
          <Box
            component={pageRenderProps.TitleIcon}
            sx={{ height: '40px', width: 'auto', mr: 1 }}
          />

          <Typography variant="h1">
            <pageRenderProps.Title />
          </Typography>
        </Box>
        <Container>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            data-testid="vacation-scheduler-header"
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => switchTab(newTab)}
              textColor="inherit"
              data-testid="vacation-scheduler-tabs"
              TabIndicatorProps={{
                sx: {
                  bgcolor: `primary`,
                },
              }}
              sx={{
                height: 'hug',
                width: '744px',
                pb: '24px',
              }}
            >
              {Object.entries(vacationScheduleTabsConfig).map(
                ([key, tabConfig]) => (
                  <Tab
                    key={key}
                    value={key}
                    icon={
                      <TabLabel
                        isSelected={key === tab}
                        color={'primary'}
                        label={tabConfig.label}
                      />
                    }
                    sx={{
                      ':hover': {
                        opacity: 0.75,
                      },
                    }}
                    data-testid={`vacation-sheduler-tab-${key}`}
                  />
                ),
              )}
            </Tabs>
          </Box>
          {tab === VacationSchedulerTabs.next ? (
            <Box
              display={'flex'}
              sx={{
                width: '100%',
                flexDirection: 'column',
                py: 0,
              }}
            >
              <DataFetching
                fetchResult={vacationsScheduledQuery}
                Loading={() => {
                  return (
                    <Box display="flex" flexDirection="column" gap="8px" pt={1}>
                      <Skeleton variant="rounded" height={300} width="100%" />
                    </Box>
                  );
                }}
                Data={({ data }) => {
                  const response = data;
                  return response.data.filter((scheduleSummary) =>
                    isFutureVacation(scheduleSummary),
                  ).length > 0 ? (
                    <Box>
                      <Typography
                        variant="body1"
                        sx={{
                          color: '#616161',
                          fontSize: '14px',
                          fontWeight: 500,
                          pb: 1,
                        }}
                      >
                        Solicitadas
                      </Typography>
                      <Box
                        data-testid="box-saldo"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          pt: 0,
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {response.data.map(
                            (scheduleSummary: VacationsScheduleSummary) => {
                              return isFutureVacation(scheduleSummary) ? (
                                <VacationsRequestedCard
                                  scheduleSummary={scheduleSummary}
                                  detailsMode={false}
                                  detailsLink={`/vacations/${contractId}/requestDetails/${scheduleSummary.startDate}`}
                                />
                              ) : null;
                            },
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : null;
                }}
              />
              <Typography
                variant="body1"
                sx={{
                  color: '#616161',
                  fontSize: '14px',
                  fontWeight: 500,
                  py: 1,
                  pt: 3,
                }}
              >
                Saldo
              </Typography>
              <DataFetching
                fetchResult={accrualPeriodsQuery}
                Loading={() => {
                  return (
                    <Box display="flex" flexDirection="column" gap="8px" pt={1}>
                      <Skeleton variant="rounded" height={300} width="100%" />
                    </Box>
                  );
                }}
                Data={({ data }) => {
                  const response = data;
                  return (
                    <Box
                      data-testid="box-saldo"
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 0,
                      }}
                    >
                      {response ? (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          {response.accrualPeriods
                            .filter((accrualPeriod) =>
                              dayjs(accrualPeriod.startDate)
                                .subtract(1, 'day')
                                .isBefore(dayjs()),
                            )
                            .map(
                              (accrualPeriod: VacationsAccrualPeriodEntry) => {
                                const props = getSaldoCardProps(
                                  accrualPeriod,
                                  contractId,
                                );
                                return <NextVacationsCardSaldo props={props} />;
                              },
                            )}
                        </Box>
                      ) : null}
                    </Box>
                  );
                }}
              />
            </Box>
          ) : null}
        </Container>
      </Container>
    </Box>
  );
}

function isFutureVacation(scheduleSummary: VacationsScheduleSummary): boolean {
  return dayjs(scheduleSummary.endDate).isAfter(dayjs());
}

function getSaldoCardProps(
  accrualPeriod: VacationsAccrualPeriodEntry,
  contractId: string,
): nextVacationsCardSaldoProps {
  const enabled: boolean = dayjs(
    accrualPeriod.concessionPeriod?.startDate,
  ).isBefore(dayjs());
  const due = getDueProp(accrualPeriod);
  const days = enabled
    ? accrualPeriod.daysAvailable
    : accrualPeriod.maximumAvailableDaysForWorker;
  const dueDate = enabled
    ? accrualPeriod.concessionPeriod?.limitDateToStartVacations
    : accrualPeriod.endDate;
  return {
    enabled: enabled,
    due: due,
    days: days,
    dueDate: dayjs(dueDate).format('DD/MM/YYYY'),
    requestLink: `/vacations/${contractId}/request?startDate=${accrualPeriod.startDate}`,
  };
}

function getDueProp(
  accrualPeriod: VacationsAccrualPeriodEntry,
): 'acquired' | 'unacquired' | 'overdue' | 'closeToDue' {
  if (
    accrualPeriod.concessionPeriod &&
    accrualPeriod.concessionPeriod.limitDateToStartVacations
  ) {
    if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations).isBefore(
        dayjs(),
      )
    ) {
      return 'overdue';
    } else if (
      dayjs(accrualPeriod.concessionPeriod.limitDateToStartVacations)
        .subtract(32, 'days')
        .isBefore(dayjs())
    ) {
      return 'closeToDue';
    }
  }
  return dayjs(accrualPeriod.concessionPeriod?.startDate).isBefore(dayjs())
    ? 'acquired'
    : 'unacquired';
}

export default VacationSchedulerPage;
