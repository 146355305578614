import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { Box, Button, Typography, useTheme } from '@mui/material';

import {
  SearchInput,
  VacationsScheduleSummary,
  fetchSearchAllScheduledVacations,
} from '@octopus/api';

import { BackButton } from '../../../../../modules/components/BackButton';
import VacationsRequestedCard from '../../../../../modules/components/vacation-scheduler/vacationsRequestedCard';
import { LoadingScene } from '../utils/RequestVacationLoadingScene';
import { canCancelRequestStatuses } from '../utils/types';

export type VacationsProps = {
  organizationId: string | undefined;
  companyId: string | undefined;
};

function VacationRequestDetailsPage({
  organizationId,
}: {
  organizationId: string | undefined;
}) {
  const theme = useTheme();
  const { contractId, startDate } = useParams();
  const [vacationRequest, setVacationRequest] =
    useState<VacationsScheduleSummary>(null);

  const searchFilter: SearchInput = {
    filtering: {
      elements: {
        contractId: [contractId],
      },
    },
  };

  const vacationsScheduledQuery = useQuery({
    queryKey: [organizationId, contractId, 'summary'],
    refetchOnWindowFocus: true,
    refetchOnMount: true,

    queryFn: () => {
      return fetchSearchAllScheduledVacations({
        pathParams: {
          organizationId: organizationId,
        },
        body: searchFilter,
      });
    },
    enabled: !!organizationId,
  });

  useEffect(() => {
    if (vacationsScheduledQuery.data) {
      setVacationRequest({
        ...vacationsScheduledQuery.data.data.find(
          (vacationsSchedule) => vacationsSchedule.startDate === startDate,
        ),
      });
    }
  }, [vacationsScheduledQuery.data, startDate]);

  if (vacationRequest === null) {
    return <LoadingScene title="Carregando detalhes do pedido" />;
  }

  function shouldShowDisclaimer(
    vacationRequest: VacationsScheduleSummary,
  ): boolean {
    return (
      !canCancelRequestStatuses.includes(vacationRequest.status) &&
      dayjs().isBefore(vacationRequest.startDate)
    );
  }

  function shouldShowCancelButton(vacationRequest: VacationsScheduleSummary) {
    return canCancelRequestStatuses.includes(vacationRequest.status);
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.secondary,
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        height: '100%',
      }}
      height={'100%'}
    >
      <BackButton position="static" />
      <Box sx={{ px: '20px', pt: 1, pb: 1 }}>
        <Typography sx={{ fontSize: '24px', fontWeight: 700 }}>
          Solicitação de férias
        </Typography>
      </Box>
      <Box
        data-testid={`vacation-request-type-header`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          px: '20px',
          gap: 2,
        }}
      >
        <VacationsRequestedCard
          scheduleSummary={vacationRequest}
          detailsMode={true}
        />
        {shouldShowDisclaimer(vacationRequest) ? (
          <Typography
            variant="subtitle1"
            sx={{ fontSize: '12px', fontWeight: 450, textAlign: 'center' }}
          >
            {`Não é possível cancelar este agendamento de férias. 
          Caso necessário, entre em contato com seu Departamento Pessoal.`}
          </Typography>
        ) : null}
        {shouldShowCancelButton(vacationRequest) ? (
          <Button
            variant="text"
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignContent: 'flex-end',
              fontSize: '16px',
              fontWeight: 700,
              lineHeight: 3,
              color: 'error.lowEmphasis',
            }}
          >
            Cancelar solicitação
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}

export default VacationRequestDetailsPage;
