import { z } from 'zod';

import { AdmissionDraftAttachmentType } from '@octopus/api';
import { Parentesco, Sexo } from '@octopus/esocial/mapper';
import { UI_TYPE, cpfSchema, dateYearMonthSchema } from '@octopus/libs/forms';

import { getOptionsFromMapper } from '../form/inputUtils';

export const getDefinition = (dependente: Dependent) => [
  {
    label: 'Nome',
    type: z.string(),
    name: 'nome',
    uiType: UI_TYPE.TEXT,
    placeholder: 'Maria de Lurdes',
    value: dependente?.nome || '',
  },
  {
    label: 'Parentesco',
    type: z.number().int(),
    name: 'parentesco',
    uiType: UI_TYPE.SELECT,
    placeholder: 'Selecione',
    fieldsOptions: getOptionsFromMapper({
      mapper: Parentesco,
      currentValue: extractParentescoType(dependente),
    }).map((option) => {
      return {
        ...option,
        fields: [
          ...(option.value === '99'
            ? [
                {
                  label: 'Descrição do parentesco',
                  type: z.string(),
                  name: 'descrDep',
                  uiType: UI_TYPE.TEXT,
                  placeholder: 'Descreva o parentesco',
                  value: dependente?.parentesco?.descrDep || '',
                },
              ]
            : []),
          {
            label: '-',
            type: z.number(),
            name: `type_${option.value}`,
            value: parseInt(option.value),
            uiType: UI_TYPE.TEXT,
            hidden: true,
          },
        ],
      };
    }),
  },
  {
    label: 'Data de nascimento',
    type: dateYearMonthSchema,
    name: 'dtNascto',
    uiType: UI_TYPE.DATE_PICKER,
    placeholder: '00/00/0000',
    value: dependente?.dtNascto,
  },
  {
    label: 'CPF',
    type: cpfSchema,
    name: 'cpf',
    uiType: UI_TYPE.TEXT,
    placeholder: '000.000.000-00',
    value: dependente?.cpf || '',
    mask: 'cpf',
  },
  {
    label: 'Sexo',
    type: z.string(),
    name: 'sexo',
    uiType: UI_TYPE.SELECT,
    options: getOptionsFromMapper({
      mapper: Sexo,
      currentValue: dependente?.sexo,
    }),
  },
  {
    label: 'Possui incapacidade física ou mental para trabalho?',
    type: z.string(),
    uiType: UI_TYPE.SELECT,
    name: 'incTrab',
    options: [
      {
        label: 'Sim',
        value: 'true',
        selected: dependente?.incTrab === 'true',
      },
      {
        label: 'Não',
        value: 'false',
        selected: dependente?.incTrab === 'false',
      },
    ],
  },
  {
    label: 'Considerado(a) nos cálculos de:',
    type: z.array(z.string()),
    name: 'calculos',
    uiType: UI_TYPE.SELECT_CHECKBOX_LIST,
    options: [
      {
        label: 'IRRF - Imposto de renda',
        value: 'calcIRRF',
        selected: dependente?.calculos?.indexOf('calcIRRF') >= 0,
      },
      {
        label: 'SF - Salário Família',
        value: 'calcSF',
        selected: dependente?.calculos?.indexOf('calcSF') >= 0,
      },
    ],
  },
];

export type Dependent = {
  nome: string;
  parentesco: {
    type: string;
    descrDep: string;
  };
  dtNascto: string;
  cpf: string;
  sexo: string;
  incTrab: 'true' | 'false';
  calculos: string[];
  file?: {
    type: AdmissionDraftAttachmentType;
    name: string;
    url: string;
    id: string;
  };
};

export function extractParentescoType(dependent: Dependent): string {
  if (!dependent?.parentesco) {
    return '';
  }

  const found = Object.entries(dependent?.parentesco).find(([k]) =>
    k.startsWith('type'),
  );

  if (!found) {
    return '';
  }

  const [_, type] = found as string[];
  return type;
}
