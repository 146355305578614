import { useEffect, useMemo, useState } from 'react';

import { Submission } from '@conform-to/react';

import { DeleteOutlined, InsertDriveFileOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, MenuItem, Select, Tooltip, Typography } from '@mui/material';

import {
  AdmissionDraftAttachmentAllowedContentType,
  AdmissionDraftAttachmentEntry,
  AdmissionDraftAttachmentType,
  fetchDeleteAdmissionDraftAttachment,
  fetchPostAdmissionDraftAttachment,
} from '@octopus/api';
import {
  admissionDraftAttachmentTypes,
  dependentAttachmentTypes,
} from '@octopus/onboarding-types';

import { FormFields } from '../../../../modules/form';
import { FormFromLayout } from '../../../../modules/form/FormFromLayout';
import { Form } from '../../../../modules/form/NewForm';
import { useFormFromDefinition } from '../../../../modules/form/useFormFromDefinition';
import { useSnackbar } from '../../../../modules/hooks/useSnackbar';
import {
  FileComponent,
  uploadFile,
} from '../../../companies/preferences/fileComponents';
import { AdmissionFormState, FormStepDefinition } from '../form/types';

import { Dependent, getDefinition } from './formStepInputs';

type Props = {
  disabled: boolean;
  isLoading: boolean;
  formState: AdmissionFormState;
  stepDefinition: FormStepDefinition;
  currentDependente: Dependent;
  submitLabel: string;
  onSubmitCallback: (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => void;
  organizationId: string;
  companyId: string;
  draftId: string;
};

export const NewAdmissionDependentForm = ({
  disabled,
  isLoading,
  formState,
  stepDefinition,
  currentDependente,
  submitLabel,
  onSubmitCallback,
  organizationId,
  companyId,
  draftId,
}: Props) => {
  const { showSnackbar } = useSnackbar();
  const onDependenteSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    formData: Submission<any, string[], any>,
  ) => {
    const submission = {
      ...formData,
      payload: { ...formData.payload, file: dependente.file },
    };

    onSubmitCallback(event, submission);
    setDependente(null);
    setFile(null);
    setFileType(undefined);
  };

  const { options } = stepDefinition;
  const [dependente, setDependente] = useState<Dependent>(currentDependente);
  const [file, setFile] = useState<File>(null);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [fileType, setFileType] =
    useState<AdmissionDraftAttachmentType>(undefined);
  const definition = getDefinition(currentDependente);
  const fullForm = useFormFromDefinition(definition, {
    id: options?.id,
    onSubmit: onDependenteSubmit,
    persistLocal: false,
  });

  const form = useMemo(
    () => ({
      payloadForm: fullForm.payloadForm,
      metaForm: fullForm.metaForm,
      id: fullForm.id,
      fieldsRenderOptions: fullForm.fieldsRenderOptions,
      loadState: fullForm.loadState,
    }),
    [
      fullForm.id,
      fullForm.payloadForm,
      fullForm.metaForm,
      fullForm.fieldsRenderOptions,
      fullForm.loadState,
    ],
  );

  useEffect(() => {
    if (dependente?.nome !== currentDependente?.nome) {
      setDependente(currentDependente);
      form.loadState(currentDependente);
    }
  }, [form, dependente, currentDependente]);

  const uploadAttachment = (newFile: File) => {
    setIsLoadingFile(true);
    fetchPostAdmissionDraftAttachment({
      pathParams: {
        organizationId,
        draftId,
      },
      body: {
        companyId,
        type: fileType,
        fileName: newFile.name,
        formStep: 'dependentes',
        contentType: newFile.type as AdmissionDraftAttachmentAllowedContentType,
        contentLength: newFile.size,
      },
    })
      .then((attachment: AdmissionDraftAttachmentEntry) => {
        uploadFile(attachment.uploadUrl, newFile)
          .then((resp) => {
            setFile(file);
            setDependente({
              ...dependente,
              file: {
                url: resp.url,
                type: fileType,
                name: newFile.name,
                id: attachment.id,
              },
            });
            setIsLoadingFile(false);
          })
          .catch((error) => {
            setFile(null);
            setIsLoadingFile(false);
            console.error(error);
            showSnackbar({
              isOpen: true,
              Message:
                'Erro ao fazer upload do documento. Por favor tente novamente ou entre em contato com a Tako.',
              variant: 'error',
              autoHideDuration: 3000,
            });
          });
      })
      .catch((error) => {
        setFile(null);
        setIsLoadingFile(false);
        console.error(error);
        showSnackbar({
          isOpen: true,
          Message:
            'Erro ao fazer upload do documento. Por favor tente novamente ou entre em contato com a Tako.',
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  const deleteFile = (fileId: string) => {
    setIsLoadingFile(true);
    fetchDeleteAdmissionDraftAttachment({
      pathParams: { organizationId, draftId, attachmentId: fileId },
    })
      .then(() => {
        setIsLoadingFile(false);
        setDependente({
          ...dependente,
          file: null,
        });
      })
      .catch((error) => {
        console.error(error);
        setIsLoadingFile(false);
        showSnackbar({
          isOpen: true,
          Message:
            'Erro ao faremover documento. Por favor tente novamente ou entre em contato com a Tako.',
          variant: 'error',
          autoHideDuration: 3000,
        });
      });
  };

  if (!formState || !stepDefinition) {
    return null;
  }

  const fileToShow = dependente?.file;
  return (
    <>
      <Form payloadForm={form.payloadForm} metaForm={form.metaForm}>
        <FormFromLayout
          fields={FormFields({
            fields: form.fieldsRenderOptions,
            formId: form.id,
          })}
          fieldsetInline={false}
        />
      </Form>
      <Typography variant="h4" component="legend" sx={{ my: 2 }}>
        Comprovante de parentesco
      </Typography>
      {fileToShow && (
        <Box
          sx={(theme) => ({
            display: 'flex',
            alignItems: 'center',
            background: theme.palette.background.secondary,
            borderRadius: 1,
            gap: 1.5,
            my: 2,
            px: 2,
            py: 1.5,
          })}
        >
          <InsertDriveFileOutlined
            color="secondary"
            fontSize="huge"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              background: theme.palette.primary.light,
              borderRadius: 1.5,
              p: 0.5,
            })}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: 0.5,
            }}
          >
            <Typography variant="caption">{fileToShow?.name}</Typography>
            <Typography variant="caption" color="gray">
              {fileToShow?.type
                ? admissionDraftAttachmentTypes[fileToShow?.type]
                : '-'}
            </Typography>
          </Box>
          <Tooltip title="Remover documento">
            <DeleteOutlined
              sx={{ marginLeft: 'auto', cursor: 'pointer', p: 1 }}
              fontSize="medium"
              color="error"
              onClick={(evt) => {
                evt.stopPropagation();
                deleteFile(fileToShow.id);
              }}
            />
          </Tooltip>
        </Box>
      )}
      {!fileToShow && (
        <Box>
          <Typography variant="caption" sx={{ display: 'flex', mt: 2, mb: 1 }}>
            Qual o tipo de documento você está enviando?
            <Typography
              variant={'caption'}
              sx={{
                fontSize: '12px',
                fontWeight: 700,
                color: (theme) => theme.palette.error.main,
                marginLeft: 0.5,
              }}
            >
              *
            </Typography>
          </Typography>
          <Select
            id="tipoDoc"
            fullWidth
            sx={{ mb: 2 }}
            required
            placeholder="Selecione..."
            value={fileType}
            onChange={(evt) =>
              setFileType(evt.target.value as AdmissionDraftAttachmentType)
            }
          >
            {dependentAttachmentTypes.map(
              (key: AdmissionDraftAttachmentType) => (
                <MenuItem key={key} value={key} selected={false}>
                  <Typography variant="inherit" noWrap>
                    {admissionDraftAttachmentTypes[key]}
                  </Typography>
                </MenuItem>
              ),
            )}
          </Select>

          <FileComponent
            disabled={!fileType}
            disabledMessage={
              !fileType
                ? 'Por favor selecione o tipo do documento no seletor acima'
                : null
            }
            setFile={(file) => uploadAttachment(file)}
            isLoading={isLoadingFile}
            file={file}
            showFilePreview={false}
          />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          mt: 2,
        }}
      >
        <LoadingButton
          loading={isLoading}
          disabled={isLoading || disabled || isLoadingFile}
          sx={(theme) => ({
            py: 1.25,
            marginLeft: 'auto',
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.up('md')]: {
              px: 6,
              marginLeft: 'auto',
            },
            [theme.breakpoints.down('md')]: {
              px: 2,
              display: 'block',
              width: 'calc(100svw - 32px)',
            },
          })}
          color="inherit"
          variant="outlined"
          type="submit"
          form={form.id}
        >
          {submitLabel}
        </LoadingButton>
      </Box>
    </>
  );
};
