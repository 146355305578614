import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { BeachAccessOutlined, ChevronRight } from '@mui/icons-material';
import { Box, Button, Card, Divider, Typography } from '@mui/material';

import { VacationsScheduleSummary } from '@octopus/api';
import { Tag } from '@octopus/ui/design-system';

export default function VacationsRequestedCard({
  scheduleSummary,
  detailsMode,
  detailsLink,
}: {
  scheduleSummary: VacationsScheduleSummary;
  detailsMode?: boolean;
  detailsLink?: string;
}) {
  const navigate = useNavigate();
  const content = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: detailsMode ? 'column' : 'row',
      }}
    >
      {renderSummary(scheduleSummary)}
      {detailsMode === false ? (
        <Button
          variant="text"
          sx={{ padding: 1, alignSelf: 'center', minWidth: 0 }}
          href={detailsLink}
        >
          <Box
            component={ChevronRight}
            sx={{ height: '24px', width: 'auto', pr: 0 }}
          />
        </Button>
      ) : (
        renderDetails(scheduleSummary)
      )}
    </Box>
  );

  return (
    <Card
      variant={'outlined'}
      sx={{
        boxSizing: 'border-box',
        py: '20px',
        px: '20px',
        borderRadius: '12px',
        my: 0.3,
      }}
      onClick={detailsMode ? undefined : () => navigate(detailsLink)}
    >
      {content}
    </Card>
  );
}

function renderSummary(scheduleSummary: VacationsScheduleSummary) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '12px',
      }}
    >
      <Box
        component={BeachAccessOutlined}
        sx={{ height: '32px', width: 'auto', color: '#BABABF' }}
      />
      <Box>
        <Typography fontSize={20} fontWeight={700} display={'inline'}>
          {`${scheduleSummary.daysTaken} dias`}
        </Typography>
        {scheduleSummary.daysSold > 0 ? (
          <Typography
            fontSize={16}
            fontWeight={500}
            display={'inline'}
            sx={(theme) => ({ color: theme.palette.strokes.heavy })}
          >
            {` | ${scheduleSummary.daysSold} dias abonados`}
          </Typography>
        ) : null}
        <Typography fontSize={14} variant="subtitle1" fontWeight={500}>
          {`De ${dayjs(scheduleSummary.startDate).format('DD/MM/YYYY')} 
               a ${dayjs(scheduleSummary.endDate).format('DD/MM/YYYY')}`}
        </Typography>
      </Box>

      {getTag(
        scheduleSummary.status,
        scheduleSummary.startDate,
        scheduleSummary.endDate,
      )}
    </Box>
  );
}

function renderDetails(scheduleSummary: VacationsScheduleSummary) {
  function shouldShowPaySlipButton(vacationRequest: VacationsScheduleSummary) {
    return ['payrollCreated', 'payrollApproved'].includes(
      vacationRequest.status,
    );
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Divider
        sx={{
          my: 2,
          alignSelf: 'stretch',
          alignContent: 'center',
          display: 'flex',
        }}
      />
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          py: 1,
          gap: 2,
        }}
      >
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={14}
            fontWeight={450}
            display={'inline'}
          >
            {`Deseja vender 10 dias das suas férias?`}
          </Typography>
          <Typography fontSize={16} variant="body1" fontWeight={500}>
            {scheduleSummary.daysSold > 0 ? 'Sim' : 'Não'}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="subtitle1"
            fontSize={14}
            fontWeight={450}
            display={'inline'}
          >
            {`Deseja receber adiantamento da parcela do 13º?`}
          </Typography>
          <Typography fontSize={16} variant="body1" fontWeight={500}>
            {scheduleSummary.thirteenthAdvance ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      </Box>
      {shouldShowPaySlipButton(scheduleSummary) ? (
        <>
          <Divider sx={{ my: 2, alignSelf: 'stretch', height: '1px' }} />
          <Box sx={{ width: '100%' }}>
            <Button
              color={'primaryAlt'}
              onClick={() => {
                console.log('entendeu mesmo?');
              }}
              sx={{ width: '100%', py: 1, px: 2, height: '40px' }}
            >
              Recibo do pagamento
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  );
}

function getTag(status: string, startDate?: string, endDate?: string) {
  let tagProps: {
    color: 'default' | 'info' | 'success' | 'warning' | 'error' | 'secondary';
    textColor: string;
    text: string;
  } = {
    color: 'default',
    textColor: '',
    text: '',
  };
  switch (status) {
    case 'approved':
    case 'payrollCreated':
    case 'payrollApproved':
      if (dayjs(startDate).isBefore(dayjs(), 'day')) {
        if (dayjs(endDate).isBefore(dayjs(), 'day')) {
          tagProps = {
            color: 'secondary',
            textColor: 'text.secondary',
            text: 'Realizada',
          };
          break;
        }
        tagProps = {
          color: 'info',
          textColor: 'info.main',
          text: 'Em curso',
        };
        break;
      }
      tagProps = {
        color: 'success',
        textColor: 'success.main',
        text: 'Aprovada',
      };
      break;
    case 'rejected':
    case 'canceled':
      tagProps = {
        color: 'error',
        textColor: 'error.main',
        text: 'Rejeitada',
      };
      break;
    case 'waitingApproval':
      tagProps = {
        color: 'warning',
        textColor: 'warning.main',
        text: 'Aguardando aprovação',
      };
      break;
  }
  return (
    <Tag color={tagProps.color}>
      <Typography
        color={tagProps.textColor}
        sx={{ fontWeight: 450, fontSize: 14 }}
      >
        {`${tagProps.text}`}
      </Typography>
    </Tag>
  );
}
