import { cpf } from 'cpf-cnpj-validator';

import {
  AdmissionDraftEntry,
  AdmissionDraftInputFormStepEnum,
  AdmissionDraftUpdateInput,
  BREstados,
  BRPaises,
  BRTipoLogradouro,
  ContractBRCltAddress,
  ContractBRCltGrauInstrucao,
  ContractBRCltRacaCor,
  ContractBRCltSexo,
  ContractBRCltTipoDependente,
} from '@octopus/api';
import { banksList } from '@octopus/contract-types';
import { Paises } from '@octopus/esocial/mapper';
import {
  formatCEP,
  formatCPF,
  formatPhoneBR,
  getOnlyDigits,
} from '@octopus/formatters';
import { admissionDraftFormSteps } from '@octopus/onboarding-types';

import {
  PixKeyType,
  parsePixKey,
} from '../../../../../modules/payrolls/parser';
import {
  Dependent,
  extractParentescoType,
} from '../../dependentes/formStepInputs';
import { getEffectiveDate } from '../inputUtils';
import { AdmissionFormState } from '../types';

const getFormStateFromEntry = ({
  admissionDraftEntry,
}: {
  admissionDraftEntry: AdmissionDraftEntry;
}): AdmissionFormState => {
  if (!admissionDraftEntry) {
    return {};
  }

  const pessoa = admissionDraftEntry.person?.br?.pessoa;
  const nascimento = admissionDraftEntry.person?.br?.nascimento;
  const pagamento = admissionDraftEntry.br?.pagamento;
  const contato = admissionDraftEntry.person?.br?.contato;
  const dependentes = admissionDraftEntry.person?.br?.dependentes;
  const endereco = admissionDraftEntry.person?.br
    ?.endereco as ContractBRCltAddress;

  const deficiencia = admissionDraftEntry.person?.br?.deficiencia;
  const deficiencias = [];
  if (deficiencia) {
    if (deficiencia.defIntelectual) {
      deficiencias.push('defIntelectual');
    }
    if (deficiencia.defAuditiva) {
      deficiencias.push('defAuditiva');
    }
    if (deficiencia.defFisica) {
      deficiencias.push('defFisica');
    }
    if (deficiencia.defMental) {
      deficiencias.push('defMental');
    }
    if (deficiencia.defVisual) {
      deficiencias.push('defVisual');
    }
  }

  return {
    nome: pessoa?.nmTrab || admissionDraftEntry.userData?.name,
    nomeSocial: pessoa?.nmSoc,
    cpf: formatCPF(pessoa?.cpfTrab),
    sexo: pessoa?.sexo,
    racaCor: pessoa?.racaCor,
    grauInstr: pessoa?.grauInstr,
    estadoCivil: pessoa?.estCiv,
    dtNascto: nascimento?.dtNascto,
    nacionalidade: nascimento?.paisNac,
    paisNascto: nascimento?.paisNascto,
    tpLograd: endereco?.tpLograd,
    dscLograd: endereco?.dscLograd,
    nrLograd: endereco?.nrLograd,
    complemento: endereco?.complemento,
    bairro: endereco?.bairro,
    cep: formatCEP(endereco?.cep),
    uf: endereco?.uf,
    codMunic: endereco?.codMunic,
    countryCode: Paises.findByName('Brasil'),
    fonePrinc: formatPhoneBR(contato?.fonePrinc),

    tipoPix: pagamento?.tipoPix || parsePixKey(pagamento?.chavePix),
    chavePix: pagamento?.chavePix,
    codigoBanco: pagamento?.codigoBanco,
    agencia: pagamento?.agencia,
    conta: pagamento?.conta,

    pessoaComDeficiencia: {
      descDeficiencia: deficiencia?.observacao,
      deficiencias: deficiencias.length === 0 ? null : deficiencias,
    },

    dependentes: dependentes?.map((dep) => {
      const calculos = [];
      if (dep.depIRRF) {
        calculos.push('calcIRRF');
      }
      if (dep.depSF) {
        calculos.push('calcSF');
      }

      return {
        nome: dep.nmDep,
        parentesco: {
          type: dep?.tpDep,
          descrDep: dep.descrDep,
        },
        descrDep: dep.descrDep,
        dtNascto: dep.dtNascto,
        cpf: formatCPF(dep.cpfDep),
        sexo: dep.sexoDep,
        incTrab: `${dep.incTrab}`,
        calculos,
        file: !dep.attachmentId
          ? null
          : {
              id: dep.attachmentId,
              type: dep.attachmentType,
              url: dep.attachmentUrl,
              name: dep.attachmentName,
            },
      };
    }),
  };
};

const getUpdateInputFromFormState = ({
  formState,
  currentStepName,
}: {
  formState: AdmissionFormState;
  currentStepName: AdmissionDraftInputFormStepEnum;
}): AdmissionDraftUpdateInput => {
  const body: AdmissionDraftUpdateInput = {
    contractType: formState.contractType,
    effectiveStartDate: getEffectiveDate(),
  };

  switch (currentStepName) {
    case admissionDraftFormSteps.dados_pessoais: {
      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(formState.nomeSocial && { nmSoc: formState.nomeSocial }),
            cpfTrab: cpf.strip(formState.cpf),
            sexo: formState.sexo as ContractBRCltSexo,
            racaCor: Number(formState.racaCor) as ContractBRCltRacaCor,
            grauInstr: 1,
            estCiv: Number(formState.estadoCivil) as ContractBRCltRacaCor,
          },
          nascimento: {
            dtNascto: formState.dtNascto,
            paisNac: Number(formState.nacionalidade) as BRPaises,
            paisNascto: Number(formState.paisNascto) as BRPaises,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.formacao_academica: {
      body.person = {
        countryCode: 'BR',
        br: {
          pessoa: {
            nmTrab: formState.nome,
            ...(formState.nomeSocial && { nmSoc: formState.nomeSocial }),
            cpfTrab: cpf.strip(formState.cpf),
            sexo: formState.sexo as ContractBRCltSexo,
            racaCor: Number(formState.racaCor) as ContractBRCltRacaCor,
            grauInstr: Number(
              formState.grauInstr,
            ) as ContractBRCltGrauInstrucao,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.endereco_e_contatos: {
      body.person = {
        countryCode: 'BR',
        br: {
          endereco: {
            tipo: 'brasil',
            tpLograd: formState.tpLograd as BRTipoLogradouro,
            dscLograd: formState.dscLograd,
            nrLograd: formState.nrLograd,
            ...(formState.complemento && {
              complemento: formState.complemento,
            }),
            bairro: formState.bairro,
            cep: getOnlyDigits(formState.cep?.toString()),
            codMunic: Number(formState.codMunic),
            uf: formState.uf as BREstados,
          },
          contato: {
            ...(formState.fonePrinc && {
              fonePrinc: getOnlyDigits(formState.fonePrinc.toString()),
            }),
            emailPrinc: formState.user_email,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.deficiencias: {
      const deficiencias = formState.pessoaComDeficiencia?.deficiencias;
      let deficienciasSet = new Set();
      if (deficiencias != null) {
        deficienciasSet = new Set(
          Array.isArray(deficiencias) ? deficiencias : [deficiencias],
        );
      }

      body.person = {
        countryCode: 'BR',
        br: {
          deficiencia: {
            observacao: formState.pessoaComDeficiencia?.descDeficiencia ?? '-',

            defFisica: deficienciasSet.has('defFisica'),
            defVisual: deficienciasSet.has('defVisual'),
            defAuditiva: deficienciasSet.has('defAuditiva'),
            defMental: deficienciasSet.has('defMental'),
            defIntelectual: deficienciasSet.has('defIntelectual'),
            reabReadap: false,
          },
        },
      };
      break;
    }
    case admissionDraftFormSteps.dados_bancarios: {
      const nomeBanco = formState.codigoBanco
        ? banksList[formState.codigoBanco]
        : null;

      const [tipoPix, chavePix] = Object.entries(formState.tipoPix)?.[0] || [];
      body.br = {
        pagamento: {
          ...(tipoPix &&
            tipoPix !== 'chave_pix_none' && { tipoPix: tipoPix as PixKeyType }),
          ...(chavePix && { chavePix: chavePix as string }),
          ...(formState.agencia && { agencia: formState.agencia }),
          ...(formState.codigoBanco && { codigoBanco: formState.codigoBanco }),
          ...(formState.conta && { conta: formState.conta }),
          ...(nomeBanco && { nomeBanco }),
        },
      };
      break;
    }
    case admissionDraftFormSteps.dependentes: {
      body.person = {
        countryCode: 'BR',
        br: {
          dependentes: formState.dependentes?.map((dependent: Dependent) => {
            const parentesco = extractParentescoType(dependent);

            return {
              nmDep: dependent.nome,
              tpDep: Number(parentesco) as ContractBRCltTipoDependente,
              dtNascto: dependent.dtNascto,
              cpfDep: cpf.strip(dependent.cpf),
              sexoDep: dependent.sexo as ContractBRCltSexo,
              incTrab: dependent.incTrab === 'true',
              depIRRF: dependent.calculos?.indexOf('calcIRRF') >= 0,
              depSF: dependent.calculos?.indexOf('calcSF') >= 0,
              descrDep: dependent.parentesco?.descrDep,
              attachmentId: dependent.file?.id,
              attachmentType: dependent.file?.type,
              attachmentUrl: dependent.file?.url,
              attachmentName: dependent.file?.name,
            };
          }),
        },
      };
      break;
    }
    default:
      return null;
  }

  return body;
};

export { getUpdateInputFromFormState, getFormStateFromEntry };
