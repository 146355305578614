import React from 'react';

import dayjs from 'dayjs';

import {
  Box,
  Button,
  Container,
  Slider,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { VacationsAccrualPeriodEntry } from '@octopus/api';

import { requestVacationObject } from '../utils/types';

export default function DurationAndDatePickerStep({
  accrualPeriod,
  requestVacationMSV,
  request,
  setRequest,
}: {
  accrualPeriod: VacationsAccrualPeriodEntry;
  requestVacationMSV: any;
  request: requestVacationObject;
  setRequest: React.Dispatch<React.SetStateAction<requestVacationObject>>;
}) {
  const theme = useTheme();
  const minDate = dayjs(accrualPeriod.concessionPeriod.startDate).isBefore(
    dayjs().add(45, 'day'),
  )
    ? dayjs().add(45, 'day')
    : dayjs(accrualPeriod.concessionPeriod.startDate);
  const maxDate = dayjs(
    accrualPeriod.concessionPeriod.limitDateToStartVacations,
  );
  const minDuration = 5;
  const maxDuration =
    accrualPeriod.daysAvailable - (request.sellVacation ? 10 : 0);
  const disableWeekends = (date: dayjs.Dayjs) => {
    return [0, 5, 6].includes(date.day());
  };
  return (
    <Container
      date-testid={'container'}
      sx={{
        pb: 2,
        flexDirection: 'column',
        display: 'inline-flex',
        justifyContent: 'stretch',
        bgcolor: theme.palette.background.secondary,
        gap: '32px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          px: 2,
          alignSelf: 'stretch',
        }}
      >
        <Typography variant="body1" fontSize={14} fontWeight={500}>
          {'Qual a data de início das férias?'}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
          <DatePicker
            value={request.date ? dayjs(request.date) : null}
            onChange={(date) => setRequest({ ...request, date: date })}
            minDate={minDate}
            maxDate={maxDate}
            slotProps={{ toolbar: { hidden: true } }}
            shouldDisableDate={disableWeekends}
            disableHighlightToday={true}
          />
        </LocalizationProvider>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          px: 2,
          alignSelf: 'stretch',
        }}
      >
        <Typography variant="body1" fontSize={14} fontWeight={500}>
          {'Quantos dias?'}
        </Typography>
        <Slider
          aria-label="Duração das férias"
          getAriaValueText={(value) => `${value}`}
          value={request.duration ?? minDuration}
          onChange={(_, newValue) =>
            setRequest({ ...request, duration: newValue as number })
          }
          min={minDuration}
          max={maxDuration}
          step={1}
          marks={[
            { value: minDuration, label: minDuration },
            { value: maxDuration - 0.2, label: maxDuration },
          ]}
          valueLabelDisplay="on"
        />
      </Box>
      <Box sx={{ flexGrow: 1, alignContent: 'flex-end' }}>
        <Button
          color="primaryAlt"
          sx={{ width: '100%', py: 1, px: 2.5, height: '48px', gap: '8px' }}
          onClick={() => {
            if (request.duration === undefined) {
              setRequest({ ...request, duration: minDuration });
            }
            requestVacationMSV.goForward();
          }}
        >
          Avançar
        </Button>
      </Box>
    </Container>
  );
}
